import {apiRequest, API_GET, API_POST} from '../../actions/api'
import {
    UPDATE_AVAILABLE_BOOKINGS,
    MAKE_BOOKING,
    CANCEL_BOOKING,

    clearAvailableBookings,
    setAvailableBookings,
    loadingAvailableBookings,
    setBookingRequestPending,
    setBookingRequestComplete,
    setBookingRequestFailed,
} from "../../actions/bookings";
import {push} from "../core/router";

const api = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([UPDATE_AVAILABLE_BOOKINGS, MAKE_BOOKING, CANCEL_BOOKING].includes(action.type)))
        return;

    switch (action.type) {
        case UPDATE_AVAILABLE_BOOKINGS:
            next(clearAvailableBookings());
            next(loadingAvailableBookings());
            next(
                apiRequest({
                    path: 'available_bookings',
                    method: API_POST,
                    success: setAvailableBookings,
                    failure: clearAvailableBookings
                })
            );
            break;
        case MAKE_BOOKING:
            next(setBookingRequestPending());
            next(
                apiRequest({
                    path: 'bookings',
                    method: API_POST,
                    data: action.payload,
                    success: () => {
                        dispatch(setBookingRequestComplete());
                        return push('/bookingconfirmation')
                    },
                    failure: setBookingRequestFailed
                })
            );

            break;
        case CANCEL_BOOKING:
            next(setBookingRequestPending());
            next(
                apiRequest({
                    path: `bookings/${action.payload.booking_uuid}/cancel`,
                    method: API_POST,
                    data: {},
                    success: () => {
                        return setBookingRequestComplete();
                    },
                    failure: () => {
                        return setBookingRequestFailed();
                    }
                })
            );

            break;
        default:
            break;
    }

};

export default api;
