import React from 'react';
import { Router, Route } from "react-router-dom";
import {isIOS} from 'react-device-detect';

import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import SplashScreen from './screens/SplashScreen';
import BookingScreen from './screens/BookingScreen';
import BookingConfirmationScreen from './screens/BookingConfirmationScreen';
import CancelBookingScreen from './screens/CancelBookingScreen';

import {history} from './redux/store';

mobiscroll.settings = {
    theme: isIOS ? 'ios' : 'material',
};

const App = () => {

    return (
        <Router history={history}>
            <Route path="/" exact component={SplashScreen} />
            <Route path="/booking/" component={BookingScreen} />
            <Route path="/bookingconfirmation/" component={BookingConfirmationScreen} />
            <Route path="/c/:booking_uuid" component={CancelBookingScreen} />
        </Router>
    );
};

export default App;