import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux'

import Lottie from 'react-lottie';
import mobiscroll from "@mobiscroll/react";
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css'

import {CallToActionButton, TimeSlotSelector} from '../components';
import {push} from '../redux/middleware/core/router'

import * as animationData from "../assets/animations/success-animation";
import moment from "moment";

const BookingConfirmationScreen = () => {

    const dispatch = useDispatch();
    const [event, setEvent] = useState(undefined);
    const booking = useSelector(state => state.booking.selected_booking);

    useEffect(() => {
        console.log(booking);
        if (booking === undefined || (Object.keys(booking).length === 0 && booking.constructor === Object)) {
            dispatch(push('/'));
        } else {
            setEvent(
                {
                    title: 'Medical Consult',
                    description: 'Booking at Standard Bank Employee Health Services',
                    location: booking.location.address_gps,
                    startTime: moment(booking.selectedTimeSlot).format(),
                    endTime: moment(booking.selectedTimeSlot).add(15, 'minutes').format()
                }
            )
        }
    }, [booking]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData['default'],
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    if (event === undefined) {
        return <></>
    }

    return (
        <Page>
            <AppHeaderParent>
                <AppHeader className={`app-header`}>
                    <p>Appointment Confirmation</p>
                </AppHeader>
            </AppHeaderParent>

            <PageContents>
                <Form inputStyle="underline" labelStyle="floating" noValidate={true}>

                    <div className="mbsc-padding">
                        <h4 className="mbsc-align-center">Your appointment has been confirmed</h4>

                        <Lottie options={defaultOptions}
                            // height={200}
                                width={200}
                        />
                        <div className="mbsc-align-center">
                            <p>{booking.location.text}
                                <br/>
                                {booking.location.address}
                                <br/>
                                {moment(booking.selectedTimeSlot).calendar(null, {
                                    sameElse: 'DD/MM/YYYY [at] LT'
                                })}
                            </p>
                            <AddToCalendar
                                buttonTemplate={{'calendar-plus-o': 'left'}}
                                listItems={[
                                    {google: 'Google'},
                                    {apple: 'Apple Calendar'},
                                    {outlook: 'Outlook'}
                                ]}
                                event={event}/>

                        </div>
                    </div>

                </Form>

                <mobiscroll.FormGroup className="mbsc-btn-group-block">
                    <CallToActionButton
                        onClick={() => dispatch(push('/'))}
                        icon="checkmark"
                        color="primary">Done</CallToActionButton>
                </mobiscroll.FormGroup>

            </PageContents>
        </Page>
    );

};

const Page = styled.div`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 42px 1fr;
        width: 100%;
        height: 100%;
    `;

const PageContents = styled(mobiscroll.Page)`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  
`;

const AppHeaderParent = styled(mobiscroll.Page)`
    &.mbsc-ios .app-header {
      border-bottom: 1px solid #ccc;
      background: #f7f7f7;
      color: #000;
    }

    &.mbsc-material .app-header {
      //background: #009688;
      background: #2E3466;
      color: #fff;
    }
`;

const AppHeader = styled.div`
    z-index: 10;
    height: 42px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 0;
    
    p {
      padding: 0;
      margin: 0;
      font-size: 20px;
    }    
`;

const Form = styled(mobiscroll.Form)`
    grid-row: 1;
    grid-column: 1;
`;


export default BookingConfirmationScreen;