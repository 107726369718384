export const UPDATE_AVAILABLE_BOOKINGS = 'UPDATE_AVAILABLE_BOOKINGS';
export const CLEAR_AVAILABLE_BOOKINGS = 'CLEAR_AVAILABLE_BOOKINGS';
export const LOADING_AVAILABLE_BOOKINGS = 'LOADING_AVAILABLE_BOOKINGS';
export const SET_AVAILABLE_BOOKINGS = 'SET_AVAILABLE_BOOKINGS';
export const MAKE_BOOKING = 'MAKE_BOOKING';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const BOOKING_REQUEST_PENDING = 'BOOKING_REQUEST_PENDING';
export const BOOKING_REQUEST_COMPLETE = 'BOOKING_REQUEST_COMPLETE';
export const BOOKING_REQUEST_FAILED = 'BOOKING_REQUEST_FAILED';

export const updateAvailableBookings = () => ({type: UPDATE_AVAILABLE_BOOKINGS});
export const clearAvailableBookings = () => ({type: CLEAR_AVAILABLE_BOOKINGS});
export const loadingAvailableBookings = () => ({type: LOADING_AVAILABLE_BOOKINGS});
export const setBookingRequestPending = () => ({type: BOOKING_REQUEST_PENDING});
export const setBookingRequestComplete = () => ({type: BOOKING_REQUEST_COMPLETE});
export const setBookingRequestFailed = () => ({type: BOOKING_REQUEST_FAILED});

export const setAvailableBookings = (bookings) => ({
    type: SET_AVAILABLE_BOOKINGS,
    payload: bookings
});

export const makeBooking = (person, booking) => ({
    type: MAKE_BOOKING,
    payload: {
        person,
        booking
    }
});

export const cancelBooking = (booking_uuid) => ({
    type: CANCEL_BOOKING,
    payload: {booking_uuid}
});