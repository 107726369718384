import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {store} from './redux/store';

import './index.css';
import App from './App';
import Div100vh from 'react-div-100vh'

import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Div100vh>
            <App/>
        </Div100vh>
    </Provider>
    , rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
