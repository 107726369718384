import React from 'react';
import styled from 'styled-components';

export default (props) => {
    const Page = styled.div`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 42px 1fr;
        width: 100%;
        height: 100%;
        
        //@media(min-width: 1024px) {
        //   padding-left: calc(50% - 512px);
        //   padding-right: calc(50% - 512px);
        //   width: 1024px;
        //}
    `;

    return (
        <Page>
            {props.children}
        </Page>
    )
}