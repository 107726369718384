import {isValidTelephoneNumber} from "./phone-number";

const validateField = (key, value, validationSettings) => {
    let invalid = false;
    if (validationSettings[key] !== undefined) {
        for (let i = 0; i < validationSettings[key].length; i++) { // interate on validators
            const validator = validationSettings[key][i];
            invalid = invalid || validator(value);
        }
    }
    let errorUpd = {};
    errorUpd[key] = invalid;
    return errorUpd;
};

// validation check functions
const requiredCheck = (value) => {
    if (!!value) {
        return false;
    } else {
        return 'This field is required';
    }
};

const minlengthCheck = (minlength, value) => {
    if (!value || value.length < minlength) {
        return 'It should be at least ' + minlength + ' characters long';
    }
    else {
        return false;
    }
};

const exactLengthCheck = (length, value) => {
    if (value.length === length)
        return false;

    return 'It should be ' + length + ' characters long';
};

const emailCheck = value => {
    if (/^\w+@\w+\.\w{2,3}$/.test(value)) {
        return false;
    } else {
        return 'This is not a valid email';
    }
};

const invalidTelephoneCheck = value => {
    if (isValidTelephoneNumber(value)) {
        return false;
    }

    return 'The supplied telephone number is invalid';
};

const luhnNumberCheck = value => {
    if (luhnCheck(value)) {
        return false;
    }

    return 'The provided input is not valid';
};

const sumDigits = number => {
    return (number + '')
        .split('')
        .map(x => parseInt(x))
        .reduce((acc, val) => acc + val);
};

const luhnCheck = num => {
    let arrayOfDigits = (num + '')
        .split('')
        .reverse()
        .map(x => parseInt(x));

    let luhnSum = sumDigits(arrayOfDigits.reduce((acc, x, i) => i % 2 !== 0 ? acc + sumDigits(x*2) :  acc + x, ''));

    return luhnSum % 10 === 0;
};


export {
    validateField,
    requiredCheck,
    minlengthCheck,
    exactLengthCheck,
    emailCheck,
    luhnNumberCheck,
    invalidTelephoneCheck
};