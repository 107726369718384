import {
    SET_AVAILABLE_BOOKINGS,
    CLEAR_AVAILABLE_BOOKINGS,
    LOADING_AVAILABLE_BOOKINGS,
    BOOKING_REQUEST_PENDING,
    BOOKING_REQUEST_COMPLETE,
    BOOKING_REQUEST_FAILED
} from '../actions/bookings';

const SET_BOOKING = 'SET_BOOKING';


const initState = {
    selected_booking: {},
    loading: false,
    available_bookings: {},
    booking_request_pending: false,
    booking_request_failed: false,
    booking_request_successful: false
};

export const bookingReducer = (state = initState, action) => {
    switch (action.type) {
        case CLEAR_AVAILABLE_BOOKINGS:
            return {
                ...state,
                booking_request_pending: false,
                booking_request_failed: false,
                booking_request_successful: false,
                selected_booking: {},
                loading: false,
                available_bookings: {}
            };

        case LOADING_AVAILABLE_BOOKINGS:
            return {...state, loading: true};

        case SET_AVAILABLE_BOOKINGS:
            return {...state, loading: false, available_bookings: action.payload};

        case SET_BOOKING:
            return {...state, selected_booking: action.payload};

        case BOOKING_REQUEST_PENDING:
            return {...state, booking_request_pending: true, booking_request_failed: false, booking_request_successful: false};

        case BOOKING_REQUEST_COMPLETE:
            return {...state, booking_request_pending: false, booking_request_failed: false, booking_request_successful: true};

        case BOOKING_REQUEST_FAILED:
            return {...state, booking_request_pending: false, booking_request_failed: true, booking_request_successful: false};

        default:
            return state;
    }
};