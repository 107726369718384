import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {createBrowserHistory} from "history";

import routerMiddleware from './middleware/core/router';
import api from './middleware/core/api';

import bookings from './middleware/feature/bookings';

import {bookingReducer} from './reducers/booking.reducer';

const rootReducer = combineReducers({booking: bookingReducer});

export const history = createBrowserHistory();

const featureMiddleware = [bookings];
const coreMiddleware = [routerMiddleware(history), api];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(...featureMiddleware, ...coreMiddleware)
);

export const store = createStore(rootReducer, {}, enhancer);
