import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux'

import Lottie from 'react-lottie';
import mobiscroll from "@mobiscroll/react";

import {push} from '../redux/middleware/core/router'
import {cancelBooking} from "../redux/actions/bookings";

import {CallToActionButton} from '../components';

import * as animationData from "../assets/animations/cancel-animation";

const CancelBookingScreen = ({match: {params: {booking_uuid}}}) => {
    const dispatch = useDispatch();

    const bookingRequestPending = useSelector(state => state.booking.booking_request_pending);
    const bookingRequestFailed = useSelector(state => state.booking.booking_request_failed);
    const bookingRequestSuccessful = useSelector(state => state.booking.booking_request_successful);

    const success = useRef();
    const failure = useRef();

    useEffect(() => {
        if (booking_uuid === undefined) {
            dispatch(push('/'));
        }
    }, [booking_uuid]);

    useEffect(() => {
        if (bookingRequestFailed) {
            failure.current.instance.show();
        }

        if (bookingRequestSuccessful) {
            success.current.instance.show();
        }
    }, [bookingRequestFailed, bookingRequestSuccessful]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData['default'],
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Page>
            <AppHeaderParent>
                <AppHeader className={`app-header`}>
                    <p>Booking Cancellation</p>
                </AppHeader>
            </AppHeaderParent>

            <PageContents>
                <Form inputStyle="underline" labelStyle="floating" noValidate={true}>

                    <div className="mbsc-padding">
                        <h4 className="mbsc-align-center">Are you sure you want to cancel your booking?</h4>

                        <Lottie options={defaultOptions}
                            // height={200}
                                width={200}
                        />
                    </div>

                </Form>

                <mobiscroll.FormGroup className="mbsc-btn-group-block">
                    <CallToActionButton
                        onClick={() => dispatch(cancelBooking(booking_uuid))}
                        icon="close"
                        color="warning"
                        disabled={bookingRequestPending}>Cancel</CallToActionButton>
                </mobiscroll.FormGroup>

                <mobiscroll.Popup
                    ref={success}
                    focusOnClose={false}
                    onSet={() => dispatch(push('/'))}
                    buttons={[{
                        text: 'Okay',
                        handler: 'set'
                    }]}
                >
                    <h3>Booking cancelled</h3>
                    <p>Your booking has been cancelled.</p>
                </mobiscroll.Popup>

                <mobiscroll.Popup
                    ref={failure}
                    focusOnClose={false}
                    onSet={() => {}}
                    buttons={[{
                        text: 'Okay',
                        handler: 'set'
                    }]}
                >
                    <h3>Error cancelling booking</h3>
                    <p>Your booking was not cancelled. Please try again later.</p>
                </mobiscroll.Popup>

            </PageContents>
        </Page>
    );

};

const Page = styled.div`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 42px 1fr;
        width: 100%;
        height: 100%;
    `;

const PageContents = styled(mobiscroll.Page)`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  
`;

const AppHeaderParent = styled(mobiscroll.Page)`
    &.mbsc-ios .app-header {
      border-bottom: 1px solid #ccc;
      background: #f7f7f7;
      color: #000;
    }

    &.mbsc-material .app-header {
      //background: #009688;
      background: #2E3466;
      color: #fff;
    }
`;

const AppHeader = styled.div`
    z-index: 10;
    height: 42px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 0;
    
    p {
      padding: 0;
      margin: 0;
      font-size: 20px;
    }    
`;

const Form = styled(mobiscroll.Form)`
    grid-row: 1;
    grid-column: 1;
`;


export default CancelBookingScreen;