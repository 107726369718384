import axios from "axios";
import {API_REQUEST, API_GET, API_POST} from '../../actions/api'
import {API_ROOT_URL, ORGANIZATION_ID, SITE_ID,} from "../../consts"

const REQUEST_TIMEOUT = 15 * 1000;

const getOptions = (authenticate = false) => {
    let options = {
        timeout: REQUEST_TIMEOUT
    };

    let headers = {
        'x-organization_id': ORGANIZATION_ID,
        'x-site_id': SITE_ID
    };

    if (authenticate) {
        headers = {
            ...headers,
            // Authorization: localStorage.getItem('token')
        }
    }


    options = {
        ...options,
        headers: headers
    };

    return options;
};

const api = ({dispatch, getState}) => next => action => {

    next(action);

    if (action.type !== API_REQUEST)
        return;

    if (action.payload === undefined)
        return;

    const {path, method, data, success, failure, authenticate, full_url} = action.payload;

    const api_url = full_url || `${API_ROOT_URL}/${path}`;

    // dispatch(startNetwork(label));

    let requestPromise = null;

    switch (method) {
        case API_GET:
            requestPromise = axios.get(api_url, getOptions(authenticate));
            break;
        case API_POST:
            requestPromise = axios.post(api_url, data, getOptions(authenticate));
            break;
        default:
            break;
    }

    if (requestPromise) {
        requestPromise
            .then(response => response.data)
            .then(_data => {


                if (success)
                    dispatch(success(_data));

                // dispatch(endNetwork(label));
            })
            .catch(error => {
                let errorObject = JSON.parse(JSON.stringify(error));
                if (errorObject.response && errorObject.response.status !== undefined) {
                    console.log("Error: " + errorObject.response.status + ', ' + errorObject.response.data);

                    if (errorObject.response.status === 403 && errorObject.response.data === 'An error occurred while validating Authentication Token') {
                        // dispatch(logoutUser());
                    }
                }
                else {
                    console.log(error);
                    // dispatch(endNetwork(label))
                }
                if (failure) {
                    dispatch(failure(error));
                }
                // dispatch(endNetwork(label))
            });
    }


};

export default api;
