import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux'

import Lottie from 'react-lottie';
import mobiscroll from "@mobiscroll/react";

import {push} from '../redux/middleware/core/router'
import {CallToActionButton} from '../components'

import StandardBankLogo from '../assets/branding/heal-logo.svg';

import * as animationData from '../assets/animations/heal-animation.json'
import splashImage from '../assets/images/heal_splash.jpg';


const SplashScreen = () => {

    const dispatch = useDispatch();


    const [animationPlaying, setAnimationPlaying] = useState(true);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData['default'],
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const eventListeners = [
        {
            eventName: 'complete',
            callback: () => setAnimationPlaying(false)
        },
    ];

    const renderBranding = () => {
        if (animationPlaying) {
            return <Lottie options={defaultOptions}
                           height={133}
                           eventListeners={eventListeners}
            />
        } else {
            return <Logo src={StandardBankLogo}/>
        }
    };

    return (

        <SplashPage>
            <BrandingContainer>
                {renderBranding()}
            </BrandingContainer>
            <MainContent>
                <MainText className="mbsc-padding mbsc-align-center">
                    <h1>Welcome</h1>
                    <h4>to the future of primary healthcare</h4>
                </MainText>

                <CallToActionButton
                    onClick={() => dispatch(push('/booking'))}
                    icon="calendar"
                    color="success"
                    block>
                    Book Now
                </CallToActionButton>
            </MainContent>

        </SplashPage>
    )
};

const SplashPage = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    height: 100%;
    background: #2E3466 url(${splashImage}) no-repeat;
    background-size: cover;
    
    @media(min-width: 1024px) {
       padding-left: calc(50% - 512px);
       padding-right: calc(50% - 512px);
       width: 1024px;
    }
`;

const BrandingContainer = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 15px;
`;


const MainContent = styled(mobiscroll.Page)`
    background-color: unset !important;
    padding: 15px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
`;


const MainText = styled.div`
    color: #ededed;
`;

const Logo = styled.img`
    
    animation: fadein 5s;
    
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    width: unset;
    
    @media (min-width: 360px) {
      width: 330px; 
    }
    
    @media (min-width: 1024px) {
      width: 400px;
    }
  
`;

export default SplashScreen;