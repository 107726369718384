import React from 'react';

import styled from "styled-components";
import mobiscroll from "@mobiscroll/react";

const CallToActionButton = styled(mobiscroll.Button)`
    height: 50px;
    font-size: 1.2em !important;
`;

export default CallToActionButton;